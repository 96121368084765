// src/features/posSlice.js

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "src/config/axios";
import { getProduct } from "./product";
import { calculateDiscount } from "src/helper/currency";
import { getTransactionDetail } from "./transaction";
import Swal from "sweetalert2";

const initialState = {
  items: [],
  totalAmount: 0,
  totalDiscount: 0,
  totalAfterDiscount: 0,
  gst: 0,
  shipping: 0,
  discount: 0,
  discountValue: 0,
  // cash
  cash: 0,
  change: 0,
  // cash
  // card
  refCode: null,
  cardNumber: null,
  // card
  paymentType: null,
  isGift: 0,
  date: null,
  note: "",
  selectedItem: {
    // image: [],
  },
  splitBill: {
    total: 0,
    tempRemaining: 0,
    remaining: 0,
    cash: 0,
    change: 0,
    data: [],
    isFinish: false,
  },
  modalSize: false,
  modalCashPayment: false,
  modalCardPayment: false,
  modalCompletedPayment: false,
  modalPaymentMethod: false,
  modalPrintReceipt: false,
  modalSplitBill: false,
  modalSplitBillMarkPayment: false,
  modalSelectStaff: false,
  modalModifyDiscount: false,

  loadingTransaction: false,
  statusTransaction: false,
  errorTransaction: "",

  staff: {
    id: null,
    name: null,
  },

  visitor: {
    male: 0,
    female: 0,
    kids: 0,
    loading: false,
  },

  detailDiscountItem: null,
};

export const submitTransaction = createAsyncThunk(
  "create-transaction",
  async (data, { dispatch, rejectWithValue }) => {
    const user = JSON.parse(localStorage.getItem("userData"));
    const store = JSON.parse(localStorage.getItem("store"));
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/v1/sales-invoice`,
        { ...data, warehouse_id: store.store },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(
        getTransactionDetail({ billing_id: response.data.data.billing_id })
      );
      dispatch(cancelAllSplitBill());
      // dispatch(getProduct());
      dispatch(toggleModalCompletedPayment());
      return {
        data: response.data.data,
        date: data.txtSalesDate,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const scanProduct = createAsyncThunk(
  "scan-product",
  async (data, { dispatch, rejectWithValue }) => {
    const user = JSON.parse(localStorage.getItem("userData"));
    const token = localStorage.getItem("token");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/v1/scan`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const result = response.data;

      if (result.data) {
        if (result.data.stock > 0) {
          const { afterDisc, discAmount } = calculateDiscount(
            parseFloat(result.data.product_price),
            result.data.product_discount
          );
          const discAmount2 = calculateDiscount(afterDisc, 0).discAmount;
          dispatch(
            addProduct({
              itemProductId: result.data.product_id,
              assets: result.data.images[0],
              product_title: result.data.product_alias,
              cat_title: result.data.product_code,
              itemPrice: parseFloat(result.data.product_price),
              itemQty: parseInt(1),
              itemProductSize: result.data.product_size,
              itemDisc: result.data.product_discount,
              itemDiscDefault: result.data.product_discount,
              itemDisc2: 0,
              // itemDiscAmount: calculateDiscount(
              //   parseFloat(result.data.product_price),
              //   result.data.product_discount
              // ).discAmount,
              // itemDiscAmount2: 0,
              itemDiscAmount: discAmount,
              itemDiscAmount2: discAmount2,
              maxQty: result.data.stock,
            })
          );
        }
      }

      return {
        data: result.data,
      };
      // dispatch(
      //   getTransactionDetail({ billing_id: response.data.data.billing_id })
      // );
      // dispatch(getProduct());
      // dispatch(toggleModalCompletedPayment());
      // return {
      //   data: response.data.data,
      //   date: data.txtSalesDate,
      // };
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const getVisitor = createAsyncThunk(
  "visitor",
  async (data, { dispatch, rejectWithValue }) => {
    const user = JSON.parse(localStorage.getItem("userData"));
    const store = JSON.parse(localStorage.getItem("store"));
    const token = localStorage.getItem("token");

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/v1/counter/index`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: { warehouse_id: store.store },
        }
      );
      const result = response.data;

      return {
        data: result.data,
      };
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateVisitor = createAsyncThunk(
  "update-visitor",
  async (data, { dispatch, rejectWithValue }) => {
    const user = JSON.parse(localStorage.getItem("userData"));
    const store = JSON.parse(localStorage.getItem("store"));
    const token = localStorage.getItem("token");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/v1/counter/store`,
        {
          ...data,
          warehouse_id: store.store,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const result = response.data;

      return {
        data: result.data,
        payload: data,
      };
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

const posSlice = createSlice({
  name: "pos",
  initialState,
  reducers: {
    selectProduct: (state, action) => {
      state.modalSize = true;
      state.selectedItem = action.payload;
    },
    addProduct: (state, action) => {
      //   state.items.push(action.payload);
      //   state.totalAmount += action.payload.price * action.payload.quantity;
      const existingProduct = state.items.find(
        (item) =>
          item.itemProductId === action.payload.itemProductId &&
          item.itemProductSize === action.payload.itemProductSize
      );
      if (existingProduct) {
        if (
          existingProduct.maxQty >=
          existingProduct.itemQty + action.payload.itemQty
        ) {
          existingProduct.itemQty += action.payload.itemQty;
          existingProduct.itemDiscAmount += action.payload.itemDiscAmount;
          // const totalAmount = action.payload.itemPrice * action.payload.itemQty;
          // state.totalAmount += totalAmount;
          // state.totalDiscount += calculateDiscount(
          //   totalAmount,
          //   action.payload.itemDisc
          // ).discAmount;
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `Product is Out of Stock`,
          });
        }
      } else {
        state.items.push(action.payload);
        const totalAmount = action.payload.itemPrice * action.payload.itemQty;
        state.totalAmount += totalAmount;
        state.totalDiscount += calculateDiscount(
          totalAmount,
          action.payload.itemDisc
        ).discAmount;
      }
      state.totalDiscount = state.items.reduce(
        (total, item) =>
          (total + item.itemDiscAmount + item.itemDiscAmount2) * item.itemQty,
        0
      );
      state.selectedItem = {};
    },
    removeProduct: (state, action) => {
      const index = state.items.findIndex(
        (item) =>
          item.itemProductId === action.payload.itemProductId &&
          item.itemProductSize === action.payload.itemProductSize
      );
      if (index !== -1) {
        const totalAmount =
          state.items[index].itemPrice * state.items[index].itemQty;
        state.totalAmount -= totalAmount;
        const { afterDisc, discAmount } = calculateDiscount(
          totalAmount,
          action.payload.itemDisc
        );
        const discAmount2 = calculateDiscount(
          afterDisc,
          action.payload.itemDisc2
        ).discAmount;
        // state.totalDiscount -= calculateDiscount(
        //   totalAmount,
        //   action.payload.itemDisc
        // ).discAmount;
        state.totalDiscount -= discAmount + discAmount2;
        state.items.splice(index, 1);
      }
      state.totalDiscount = state.items.reduce(
        (total, item) =>
          (total + item.itemDiscAmount + item.itemDiscAmount2) * item.itemQty,
        0
      );
    },
    removeAllProducts: (state) => {
      state.items = [];
      state.totalAmount = 0;
      state.totalDiscount = 0;
    },
    incrementQuantity: (state, action) => {
      console.log("action ", action.payload);
      const item = state.items.find(
        (item) =>
          item.itemProductId === action.payload.itemProductId &&
          item.itemProductSize === action.payload.itemProductSize
      );
      if (item) {
        item.itemQty += 1;
        // item.itemDiscAmount += action.payload.itemDiscAmount;
        const totalAmount = item.itemPrice;
        state.totalAmount += totalAmount;
        // const { afterDisc, discAmount } = calculateDiscount(
        //   totalAmount,
        //   action.payload.itemDisc
        // );
        // const discAmount2 = calculateDiscount(
        //   afterDisc,
        //   action.payload.itemDisc2
        // ).discAmount;
        // state.totalDiscount += discAmount + discAmount2;
      }
      state.totalDiscount = state.items.reduce(
        (total, item) =>
          (total + item.itemDiscAmount + item.itemDiscAmount2) * item.itemQty,
        0
      );
    },
    decrementQuantity: (state, action) => {
      const item = state.items.find(
        (item) =>
          item.itemProductId === action.payload.itemProductId &&
          item.itemProductSize === action.payload.itemProductSize
      );
      if (item && item.itemQty > 1) {
        item.itemQty -= 1;
        // item.itemDiscAmount -= action.payload.itemDiscAmount;
        const totalAmount = item.itemPrice;
        state.totalAmount -= totalAmount;
        // const { afterDisc, discAmount } = calculateDiscount(
        //   totalAmount,
        //   action.payload.itemDisc
        // );
        // const discAmount2 = calculateDiscount(
        //   afterDisc,
        //   action.payload.itemDisc2
        // ).discAmount;
        // state.totalDiscount -= discAmount + discAmount2;
      }
      state.totalDiscount = state.items.reduce(
        (total, item) =>
          (total + item.itemDiscAmount + item.itemDiscAmount2) * item.itemQty,
        0
      );
    },
    toggleModalSize: (state) => {
      if (state.modalSize) {
        state.selectedItem = {};
      }
      state.modalSize = !state.modalSize;
    },
    toggleModalCashPayment: (state) => {
      if (state.modalCashPayment) {
        state.cash = 0;
        state.change = 0;
      }
      // if (state.items.length > 0) {
      //   state.modalCardPayment = false;
      //   state.paymentType = "CASH";
      //   state.modalCashPayment = !state.modalCashPayment;
      // }
      state.modalPaymentMethod = true;
      state.modalCardPayment = false;
      state.modalCashPayment = false;
    },
    toggleModalCardPayment: (state) => {
      // if (state.items.length > 0) {
      //   state.modalCashPayment = false;
      //   state.paymentType = "CARD";
      //   state.modalCardPayment = !state.modalCardPayment;
      // }
      state.modalPaymentMethod = true;
      state.modalCardPayment = false;
      state.modalCashPayment = false;
    },
    toggleModalCompletedPayment: (state) => {
      state.modalCompletedPayment = !state.modalCompletedPayment;
      if (state.modalCompletedPayment) {
        state.modalCashPayment = false;
        state.modalCardPayment = false;
        state.splitBill.isFinish = false;
      }
    },
    toggleModalPaymentMethod: (state) => {
      if (state.modalPaymentMethod) {
        state.paymentType = null;
      }
      state.modalPaymentMethod = !state.modalPaymentMethod;
      // if (state.modalCompletedPayment) {
      //   state.modalCashPayment = false;
      //   state.modalCardPayment = false;
      // }
    },
    toggleSelectedPaymentMethod: (state) => {
      state.modalPaymentMethod = false;
      if (state.paymentType == "CASH RUPIAH") {
        state.modalCashPayment = true;
      } else {
        if (state.paymentType == "SPLIT BILL") {
          state.modalSplitBill = true;
          state.modalCardPayment = false;
        } else {
          state.modalSplitBill = false;
          state.modalCardPayment = true;
        }
      }
      // if (state.modalCompletedPayment) {
      //   state.modalCashPayment = false;
      //   state.modalCardPayment = false;
      // }
    },
    togglePrintReceipt: (state) => {
      if (state.modalPrintReceipt) {
        state.modalCompletedPayment = false;
      }
      state.modalPrintReceipt = !state.modalPrintReceipt;
    },
    setCash: (state, action) => {
      if (action.payload > 0) {
        state.cash = action.payload;
        const change = action.payload - state.totalAmount + state.totalDiscount;
        state.change = change;
      } else {
        state.change = 0;
        state.cash = 0;
      }
    },
    setCashSplitBill: (state, action) => {
      if (action.payload > 0) {
        state.splitBill.cash = action.payload;
        if (state.splitBill.data.length > 0) {
          const total = state.splitBill.tempRemaining;
          const diff = total - action.payload;
          if (diff >= 0) {
            state.splitBill.remaining = diff;
            state.splitBill.change = 0;
          } else {
            state.splitBill.remaining = 0;
            state.splitBill.change = diff;
          }
        } else {
          const total = state.totalAmount - state.totalDiscount;
          const diff = total - action.payload;
          if (diff >= 0) {
            state.splitBill.remaining = diff;
            state.splitBill.tempRemaining = diff;
            state.splitBill.change = 0;
          } else {
            state.splitBill.remaining = 0;
            state.splitBill.tempRemaining = 0;
            state.splitBill.change = diff;
          }
        }
        // const change = action.payload - state.totalAmount + state.totalDiscount;
        // state.change = change;
      } else {
        // state.change = 0;
        state.splitBill.remaining = state.splitBill.tempRemaining;
        state.splitBill.cash = 0;
      }
    },
    setNote: (state, action) => {
      state.note = action.payload;
    },
    setRefCode: (state, action) => {
      state.refCode = action.payload;
    },
    setPaymentMethod: (state, action) => {
      state.paymentType = action.payload;
      // if (action.payload == "SPLIT BILL") {
      //   state.modalSplitBill = true;
      // } else {
      //   state.modalSplitBill = false;
      // }
    },
    setPaymentMethodSplitBill: (state, action) => {
      // state.paymentType = action.payload;
      if (state.totalAmount - state.totalDiscount - state.cash > 0) {
        state.splitBill.data.push(action.payload);
      }
      if (state.splitBill.data.length == 0) {
        state.splitBill.remaining =
          state.totalAmount - state.totalDiscount - state.splitBill.cash;
        state.splitBill.tempRemaining =
          state.totalAmount - state.totalDiscount - state.splitBill.cash;
      } else {
        state.splitBill.tempRemaining = state.splitBill.remaining;
      }
      state.modalSplitBillMarkPayment = true;
      // if (action.payload == "SPLIT BILL") {
      //   state.modalSplitBill = true;
      // } else {
      //   state.modalSplitBill = false;
      // }
    },
    setMarkSplitBill: (state, action) => {
      const index = state.splitBill.data.length - 1;
      state.splitBill.data[index].status == true;
      if (state.splitBill.remaining > 0) {
        state.splitBill.isFinish = false;
        state.modalSplitBill = true;
        state.modalSplitBillMarkPayment = false;
        state.splitBill.cash = 0;
      } else {
        state.modalSplitBill = false;
        state.modalSplitBillMarkPayment = false;
        state.splitBill.isFinish = true;
      }
    },
    setCardNumber: (state, action) => {
      state.cardNumber = action.payload;
    },
    finishOrder: (state) => {
      state.items = [];
      state.totalAmount = 0;
      state.totalDiscount = 0;
      state.gst = 0;
      state.shipping = 0;
      state.discount = 0;
      state.discountValue = 0;
      state.cash = 0;
      state.note = "";
      state.change = 0;
      state.selectedItem = {
        // image: [],
      };
      state.modalSize = false;
      state.modalCashPayment = false;
      state.modalCompletedPayment = false;
      state.modalPrintReceipt = false;
      state.modalPaymentMethod = false;
      state.paymentType = null;
    },
    closeModalCompletedPayment: (state) => {
      state.modalCompletedPayment = false;
    },
    resetItemStaging: (state) => {
      state.items = [];
      state.totalAmount = 0;
      state.totalDiscount = 0;
    },
    checkActiveStaff: (state) => {
      const staff = JSON.parse(localStorage.getItem("staff"));
      if (staff) {
        state.staff.id = staff.id;
        state.staff.name = staff.name;
        state.modalSelectStaff = false;
      } else {
        state.staff.id = null;
        state.staff.name = null;
        state.modalSelectStaff = true;
      }
    },
    setActiveStaff: (state, action) => {
      state.staff.id = action.payload.id;
      state.staff.name = action.payload.name;
      localStorage.setItem(
        "staff",
        JSON.stringify({ id: action.payload.id, name: action.payload.name })
      );
      state.modalSelectStaff = false;
    },
    cancelAllSplitBill: (state, action) => {
      state.modalSplitBill = false;
      state.splitBill.isFinish = false;
      state.splitBill.total = 0;
      state.splitBill.tempRemaining = 0;
      state.splitBill.remaining = 0;
      state.splitBill.cash = 0;
      state.splitBill.change = 0;
      state.splitBill.data = [];
      state.paymentType = "";
    },
    setModalSplitBill: (state, action) => {
      state.modalSplitBill = action.payload;
    },
    setModalSplitBillMarkPayment: (state, action) => {
      state.modalSplitBillMarkPayment = action.payload;
    },
    setRevertSplitBillRemaining: (state, action) => {
      const index = state.splitBill.data.length - 1;
      const temp = state.splitBill.data[index];
      const prevTotal = temp.total;
      state.splitBill.remaining = state.splitBill.remaining + prevTotal;
      state.splitBill.tempRemaining = state.splitBill.tempRemaining + prevTotal;
      state.splitBill.cash = 0;
      state.splitBill.data.splice(index, 1);
    },
    setBuyAsGift: (state, { payload }) => {
      state.isGift = payload;
    },
    resetActiveStaff: (state) => {
      state.staff = {
        id: null,
        name: null,
      };
    },
    setModalModifyDiscount: (state, { payload }) => {
      state.modalModifyDiscount = payload;
    },
    setDetailDiscountItem: (state, { payload }) => {
      state.detailDiscountItem = {
        ...payload,
        subTotal: payload.itemPrice * payload.itemQty,
      };
      if (payload) {
        state.modalModifyDiscount = true;
      } else {
        state.modalModifyDiscount = false;
      }
    },
    setModidfyDiscountData: (state, { payload }) => {
      state.detailDiscountItem[payload.column] = payload.value;
    },
    applyModifyDiscountData: (state, { payload }) => {
      const item = state.items.find(
        (item) =>
          item.itemProductId === payload.itemProductId &&
          item.itemProductSize === payload.itemProductSize
      );
      if (item) {
        item.itemDisc = payload.itemDisc;
        item.itemDisc2 = payload.itemDisc2;
        item.itemDiscAmount = payload.itemDiscAmount;
        item.itemDiscAmount2 = payload.itemDiscAmount2;

        // const { afterDisc, discAmount } = calculateDiscount(
        //   state.totalAmount,
        //   payload.itemDisc
        // );
        // const discAmount2 = calculateDiscount(
        //   afterDisc,
        //   payload.itemDisc2
        // ).discAmount;

        // state.totalDiscount += payload.itemDiscAmount + payload.itemDiscAmount2;

        // state.totalDiscount += calculateDiscount(
        //   totalAmount,
        //   payload.itemDisc
        // ).discAmount;
        state.modalModifyDiscount = false;
      }
      state.totalDiscount = state.items.reduce(
        (total, item) =>
          (total + item.itemDiscAmount + item.itemDiscAmount2) * item.itemQty,
        0
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(submitTransaction.fulfilled, (state, action) => {
      state.loadingTransaction = false;
      state.statusTransaction = true;
      state.modalCardPayment = false;
      state.modalCashPayment = false;
      state.errorTransaction = "";
      state.date = action.payload.date;
    });
    builder.addCase(submitTransaction.pending, (state) => {
      state.loadingTransaction = true;
      state.statusTransaction = false;
      state.errorTransaction = "";
    });
    builder.addCase(submitTransaction.rejected, (state, action) => {
      state.loadingTransaction = false;
      state.statusTransaction = false;
      state.errorTransaction = action.payload.data;
    });

    builder.addCase(getVisitor.fulfilled, (state, { payload }) => {
      state.visitor.female = payload.data.female;
      state.visitor.male = payload.data.male;
      state.visitor.kids = payload.data.kids;
      state.visitor.loading = false;
    });
    builder.addCase(getVisitor.pending, (state) => {
      state.visitor.loading = true;
    });
    builder.addCase(getVisitor.rejected, (state, action) => {
      state.visitor.loading = true;
    });

    builder.addCase(updateVisitor.fulfilled, (state, { payload }) => {
      state.visitor.loading = false;
      if (payload.payload.method === "plus") {
        state.visitor[payload.payload.type.toLowerCase()] =
          parseInt(state.visitor[payload.payload.type.toLowerCase()]) + 1;
      } else {
        state.visitor[payload.payload.type.toLowerCase()] =
          parseInt(state.visitor[payload.payload.type.toLowerCase()]) - 1;
      }
    });
    builder.addCase(updateVisitor.pending, (state) => {
      state.visitor.loading = true;
    });
    builder.addCase(updateVisitor.rejected, (state, { payload }) => {
      state.visitor.loading = true;
    });

    builder.addCase(scanProduct.fulfilled, (state, action) => {
      if (action.payload.data.product_code) {
        if (action.payload.data.stock <= 0) {
          document.dispatchEvent(new Event("playFailureSound"));
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `Product is Out of Stock`,
          });
        } else {
          document.dispatchEvent(new Event("playSuccessSound"));
        }
      } else {
        document.dispatchEvent(new Event("playFailureSound"));
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `Product Not Found`,
        });
      }
      state.selectedItem = {};
    });
    builder.addCase(scanProduct.pending, (state) => {});
    builder.addCase(scanProduct.rejected, (state, action) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${action.payload.message}`,
      });
      state.selectedItem = {};
    });
  },
});

export const {
  selectProduct,
  addProduct,
  removeProduct,
  removeAllProducts,
  incrementQuantity,
  decrementQuantity,
  toggleModalSize,
  toggleModalCashPayment,
  toggleModalCardPayment,
  toggleModalCompletedPayment,
  togglePrintReceipt,
  toggleModalPaymentMethod,
  finishOrder,
  setCash,
  setCashSplitBill,
  setNote,
  setCardNumber,
  setRefCode,
  setPaymentMethod,
  setPaymentMethodSplitBill,
  setMarkSplitBill,
  toggleSelectedPaymentMethod,
  closeModalCompletedPayment,
  resetItemStaging,
  checkActiveStaff,
  setActiveStaff,
  cancelAllSplitBill,
  setModalSplitBill,
  setModalSplitBillMarkPayment,
  setRevertSplitBillRemaining,
  setBuyAsGift,
  resetActiveStaff,
  // Modify Discount
  setModalModifyDiscount,
  setDetailDiscountItem,
  setModidfyDiscountData,
  applyModifyDiscountData,
} = posSlice.actions;
export default posSlice.reducer;
