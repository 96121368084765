import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { MinusCircle, PlusCircle, ShoppingBag, Trash2 } from "react-feather";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
// import ImageWithBasePath from "src/core/img/imagewithbasebath";
import { calculateDiscount, rupiahCurrency } from "src/helper/currency";
import {
  decrementQuantity,
  incrementQuantity,
  removeProduct,
  setDetailDiscountItem,
  setModalModifyDiscount,
} from "src/redux/store/pos";

const Item = ({ item }) => {
  const dispatch = useDispatch();

  const handleRemove = () => {
    dispatch(
      removeProduct({
        itemProductId: item.itemProductId,
        itemProductSize: item.itemProductSize,
        itemDisc: item.itemDisc,
        itemDisc2: item.itemDisc2,
        // itemDiscAmount: calculateDiscount(item.itemPrice, item.itemDisc)
        //   .discAmount,
      })
    );
  };

  const incrementQty = () => {
    if (item.itemQty < item.maxQty) {
      dispatch(
        incrementQuantity({
          itemProductId: item.itemProductId,
          itemProductSize: item.itemProductSize,
          itemDisc: item.itemDisc,
          itemDisc2: item.itemDisc2,
          // itemDiscAmount: calculateDiscount(item.itemPrice, item.itemDisc)
          //   .discAmount,
        })
      );
    }
  };

  const decrementQty = () => {
    dispatch(
      decrementQuantity({
        itemProductId: item.itemProductId,
        itemProductSize: item.itemProductSize,
        itemDisc: item.itemDisc,
        itemDisc2: item.itemDisc2,
      })
    );
  };

  const handleModifyDiscount = (item) => {
    dispatch(setDetailDiscountItem(item));
  };

  return (
    <div className="product-list d-flex align-items-center justify-content-between">
      <div
        className="d-flex flex-wrap gap-2 align-items-start product-info"
        style={{ userSelect: "none" }}
      >
        <Link
          to="#"
          className="img-bg"
          style={{ flexShrink: 0, height: 50, width: 50 }}
        >
          {item?.assets ? (
            <img
              src={`${process.env.REACT_APP_ASSET}/${item?.assets}`}
              alt="Products"
              width={60}
            />
          ) : (
            <ShoppingBag />
          )}
        </Link>
        <div className="info">
          <small>
            <Link
              to="#"
              className="d-inline-block text-truncate"
              // style="max-width: 150px;"
              style={{ maxWidth: "150px" }}
            >
              {item.product_title}
            </Link>
          </small>
          <h6>
            <Link to="#">{item.cat_title}</Link>
          </h6>
          <div className="d-flex align-items-start gap-2">
            <div className="d-flex flex-column">
              <div className="fw-bolder">
                {/* save for history */}
                {rupiahCurrency.format(
                  parseFloat(
                    calculateDiscount(
                      parseFloat(
                        calculateDiscount(item.itemPrice, item.itemDisc)
                          .afterDisc
                      ),
                      item.itemDisc2
                    ).afterDisc
                  )
                  // calculateDiscount(item.itemPrice, item.itemDisc).afterDisc
                )}
                {/* {rupiahCurrency.format(
                  item.itemPrice - item.itemDiscAmount - item.itemDiscAmount2
                )} */}
              </div>
              {item.itemDisc > 0 || item.itemDisc2 > 0 ? (
                <del>{rupiahCurrency.format(item.itemPrice)}</del>
              ) : null}
            </div>
            <div className="badge bg-secondary" style={{ fontSize: "10px" }}>
              {item.itemProductSize}
            </div>
            {item.itemDisc > 0 ? (
              <div className="badge bg-secondary" style={{ fontSize: "10px" }}>
                {`${item.itemDisc}%`}{" "}
                {item.itemDisc2 > 0 ? `+ ${item.itemDisc2}%` : ``} Off
              </div>
            ) : null}
          </div>
        </div>
        <button
          className="ms-2 btn btn-outline-primary d-flex align-items-center gap-2"
          onClick={() => handleModifyDiscount(item)}
        >
          <i className="las la-edit"></i>
          <small>Edit Discount</small>
        </button>
      </div>

      <div className="qty-item text-center" style={{ userSelect: "none" }}>
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="tooltip-minus">Minus</Tooltip>}
        >
          <div
            role="button"
            className="dec d-flex justify-content-center align-items-center"
            onClick={() => decrementQty()}
          >
            <MinusCircle className="feather-14" />
          </div>
        </OverlayTrigger>

        <input
          type="text"
          className="form-control text-center"
          name="qty"
          value={item.itemQty}
          readOnly
        />

        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="tooltip-plus">Plus</Tooltip>}
        >
          <div
            role="button"
            className="inc d-flex justify-content-center align-items-center"
            onClick={() => incrementQty()}
          >
            <PlusCircle className="feather-14" />
          </div>
        </OverlayTrigger>
      </div>

      <div className="d-flex align-items-center action">
        {/* <Link
          className="btn-icon edit-icon me-2"
          to="#"
          data-bs-toggle="modal"
          data-bs-target="#edit-product"
        >
          <i data-feather="edit" className="feather-14" />
          <Edit className="feather-14" />
        </Link> */}
        <div
          role="button"
          className="btn-icon delete-icon confirm-text"
          onClick={() => handleRemove()}
        >
          <Trash2 className="feather-14 text-danger" />
        </div>
      </div>
    </div>
  );
};

export default Item;
