import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  applyModifyDiscountData,
  setModalModifyDiscount,
  setModidfyDiscountData,
  toggleModalSize,
} from "src/redux/store/pos";
import "./modal.css";
import { ShoppingBag } from "react-feather";
import { Link } from "react-router-dom";
import { calculateDiscount, rupiahCurrency } from "src/helper/currency";
import { InputNumber } from "antd";

const ModifyDiscount = () => {
  const pos = useSelector((state) => state.pos);
  const [discAmount, setDiscAmount] = useState(0);
  const [discAmount2, setDiscAmount2] = useState(0);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setModalModifyDiscount(false));
  };

  useEffect(() => {
    if (pos.detailDiscountItem) {
      const { discAmount, afterDisc } = calculateDiscount(
        pos.detailDiscountItem.itemPrice,
        pos.detailDiscountItem.itemDisc
      );
      const disc2 = calculateDiscount(
        afterDisc,
        pos.detailDiscountItem.itemDisc2
      ).discAmount;
      setDiscAmount(discAmount);
      setDiscAmount2(disc2);
    }
  }, [pos.detailDiscountItem]);

  const handleClear = () => {
    dispatch(
      setModidfyDiscountData({
        column: "itemDisc",
        value: pos.detailDiscountItem?.itemDiscDefault,
      })
    );
    dispatch(
      setModidfyDiscountData({
        column: "itemDisc2",
        value: 0,
      })
    );
    dispatch(
      setModidfyDiscountData({
        column: "itemDiscAmount",
        value: calculateDiscount(
          pos.detailDiscountItem.itemPrice,
          pos.detailDiscountItem.itemDiscDefault
        ).discAmount,
      })
    );
    dispatch(
      setModidfyDiscountData({
        column: "itemDiscAmount2",
        value: 0,
      })
    );
  };

  const handleApply = () => {
    dispatch(
      applyModifyDiscountData({
        itemDisc: pos.detailDiscountItem?.itemDisc,
        itemDisc2: pos.detailDiscountItem?.itemDisc2,
        itemDiscAmount: discAmount,
        itemDiscAmount2: discAmount2,
        itemProductId: pos.detailDiscountItem?.itemProductId,
        itemProductSize: pos.detailDiscountItem?.itemProductSize,
      })
    );
  };

  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={pos.modalModifyDiscount}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <div className="d-flex flex-column">
          <Modal.Title>Modify Discount</Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-12 mb-2">
            <div
              className="d-flex flex-wrap gap-2 align-items-start justify-content-between product-info w-100"
              style={{ userSelect: "none" }}
            >
              <div className="info">
                <small>
                  <Link to="#" className="d-inline-block fs-6">
                    {pos.detailDiscountItem?.product_title}
                  </Link>
                </small>
                <h6>
                  <Link to="#">
                    {pos.detailDiscountItem?.cat_title}{" "}
                    <span
                      className="badge bg-secondary ms-2"
                      style={{ fontSize: "10px" }}
                    >
                      {pos.detailDiscountItem?.itemProductSize}
                    </span>
                  </Link>
                </h6>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="d-flex align-items-center gap-2">
              <div className="input-blocks">
                <label>Discount #1</label>
                <InputNumber
                  min={0}
                  max={100}
                  suffix="%"
                  size="large"
                  value={pos.detailDiscountItem?.itemDisc}
                  onChange={(value) =>
                    dispatch(
                      setModidfyDiscountData({
                        column: "itemDisc",
                        value: value,
                      })
                    )
                  }
                />
              </div>
              <div className="input-blocks">
                <label>Discount #2</label>
                <InputNumber
                  min={0}
                  max={100}
                  suffix="%"
                  size="large"
                  value={pos.detailDiscountItem?.itemDisc2}
                  onChange={(value) =>
                    dispatch(
                      setModidfyDiscountData({
                        column: "itemDisc2",
                        value: value,
                      })
                    )
                  }
                />
              </div>
            </div>
          </div>
          <div className="col-12 d-flex justify-content-end">
            <table className="table-condensed w-100">
              <tr>
                <td>Subtotal</td>
                <td className="text-end">
                  <div className="fw-bold">
                    {rupiahCurrency.format(pos.detailDiscountItem?.itemPrice)}
                  </div>
                </td>
              </tr>
              <tr>
                <td>Disc Amount {pos.detailDiscountItem?.itemDisc} %</td>
                <td className="text-end">
                  <div className="fw-bold">
                    {rupiahCurrency.format(discAmount)}
                  </div>
                </td>
              </tr>
              <tr>
                <td>Disc Amount {pos.detailDiscountItem?.itemDisc2} %</td>
                <td className="text-end">
                  <div className="fw-bold">
                    {rupiahCurrency.format(discAmount2)}
                  </div>
                </td>
              </tr>
              <tr className="fs-5">
                <td>Total :</td>
                <td className="text-end">
                  <div className="fw-bold">
                    {rupiahCurrency.format(
                      pos.detailDiscountItem?.itemPrice -
                        discAmount -
                        discAmount2
                    )}
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-end gap-2 w-100">
          <button
            className="btn btn-default text-primary"
            onClick={handleClear}
          >
            Reset
          </button>
          <button className="btn btn-primary" onClick={handleApply}>
            Apply
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ModifyDiscount;
